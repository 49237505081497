.button
  display: inline-flex
  justify-content: center
  align-items: center
  height: 50px
  padding: 0 56px
  color: $white !important
  font-size: 1em
  font-weight: 500
  letter-spacing: .05em
  border-radius: 4px
  transition: all ease-out .25s
  background-color: $pink !important
  &:hover
    color: $white !important
    background-color: $theme !important

#top
  .button
    +v-sm
      display: inline-flex
      width: 40%
      margin: 0 24px 0 0
    +v-xs
      display: flex
      width: 100%
      margin: 24px 0
